




import { Component, Vue } from 'vue-property-decorator';
import CartOrderSuccess from '@/app/modules/cart/components/CartOrderSuccess.vue';

@Component({
  components: {
    CartOrderSuccess,
  },
})
export default class CartOrderSuccessView extends Vue {
  /**
   * Getters
   */
  private get orderId(): number {
    return Number(this.$route.params.id);
  }
}
